/**
 * Home
 *
 * 01. Animated Headline
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

    /**
     * Spotlight
     *
     * @requires plugins/jquery.flickity.js
     */
    $('.js-spotlight').flickity({
        autoPlay: true,
        prevNextButtons: false,
        draggable: false,
        setGallerySize: false
    });


    /**
     * Courses
     *
     * @requires plugins/jquery.flickity.js
     *
     * @link https://codepen.io/desandro/pres/VLPJqO
     * @link https://codepen.io/desandro/pen/KVwVqa Remove FOUC
     */
    (function () {
        var courses = $('.js-courses');

        if (!courses.length) {
            return;
        }

        courses.flickity({
            autoPlay: 5000,
            pauseAutoPlayOnHover: true,
            draggable: true,
            prevNextButtons: false,
            pageDots: false,
            cellAlign: 'left',
            contain: true
        });

        $('.courses-btn--previous').on( 'click', function() {
            courses.flickity('previous');
        });

        $('.courses-btn--next').on( 'click', function() {
            courses.flickity('next');
        });
    }());

}(jQuery));