/**
 * Sidebar
 *
 * 01. Navigation
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';

    /**
     * Navigation
     *
     * @requires plugins/jquery.accordion.js
     */
    (function () {
        var submenu = $('.js-submenu');

        $('<svg class="submenu__accordion"><use xlink:href="#icons--chevron-right"></use></svg>').appendTo(submenu.find('.submenu__dropdown').children('.submenu__link'));

        submenu.find('.submenu__item--parent').addClass('submenu__item--open');
        submenu.find('.submenu__item--active').addClass('submenu__item--open');

        submenu.accordion({
            panelSelector	: '.submenu__dropdown',
            titleSelector	: '> .submenu__link .submenu__accordion',
            contentSelector	: '.submenu__sub',
            activeClass		: 'submenu__item--open'
        });
    }());

}(jQuery));