/**
 * Home
 *
 * 01. Courses
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

    /**
     * Spotlight
     *
     * @requires plugins/jquery.flickity.js
     */
    (function () {
        var spotlight = $('.js-spotlight');

        if (!spotlight.length) {
            return;
        }

        spotlight.flickity({
            autoPlay: true,
            prevNextButtons: false,
            draggable: false,
            setGallerySize: false
        });
    }());

}(jQuery));