/**
 * Global
 *
 * 01. SVG
 * 02. Social Share
 * 03. Video Replacement
 * 04. Fluid Video
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

	/**
	 * SVG
	 *
	 * @requires libraries/svg4everybody.js
	 * @requires ie/svg4everybody.legacy.js
	 */
	svg4everybody();


	/**
	 * Fluid Video
	 *
	 * @requires plugins/jquery.fitvids.js
	 */
    $('.single__body').fitVids();


    /**
     * Google Maps
     *
     * @summary Mappa personalizzata con https://snazzymaps.com/
     * @requires plugins/jquery.googlemaps.js
     */
    (function () {
        var maps = $('.js-gmap');

        if (!maps.length) {
            return;
        }

        //window.onCookiePolicyAccepted(function () {
        var d = document,
            t = 'script';

        function l(u) {
            var j = d.createElement(t),
                s = d.getElementsByTagName(t)[0];

            j.src = u;
            j.async = 1;
            s.parentNode.insertBefore(j, s);
        }

        l('//maps.googleapis.com/maps/api/js?key=' + gApiKey + '&v=3.exp&callback=initialize');
        //});

        window.initialize = function () {
            maps.googlemaps();
        };
    }());
	
}(jQuery));
