/**
 * Header
 *
 * 01. Navigation
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';

    /**
     * Scroll Hidden/Visible
     *
     * @summary Header scroll hidden and visible
     */
    var header = $('.js-header'),
        headerFixed = 'header--fixed',
        headerVisible = 'header--visible',
        headerHeight = header.height(),
        $window = $(window);

    $window.on(
        'scroll',
        {
            previousTop: 0
        },
        function () {
            var currentTop = $window.scrollTop();

            // check if user is scrolling up
            if (currentTop < this.previousTop) {
                // if scrolling up...
                if (currentTop > 0 && header.hasClass(headerFixed)) {
                    header.addClass(headerVisible);
                } else {
                    header.removeClass(headerVisible + ' ' + headerFixed);
                }
            } else if (currentTop != this.previousTop) {
                // if scrolling down...
                header.removeClass(headerVisible);
                if (currentTop > headerHeight && !header.hasClass(headerFixed)) {
                    header.addClass(headerFixed);
                }
            }
            this.previousTop = currentTop;
        }
    );

    /**
     * Navigation
     *
     * @link https://codepen.io/sunmughan/pen/KvGpbx
     */
    (function () {
        $('.menu-list > li:has( > ul)').addClass('menu-dropdown-icon');
        // Checks if li has sub (ul) and adds class for toggle icon - just an UI

        $('.menu-list > li').hover(function (e) {
            if ($(window).width() > 1199) {
                $(this).children('ul').stop(true, false).fadeToggle(150);
                e.preventDefault();
            }
        });
        // If width is more than 943px dropdowns are displayed on hover

        $('.menu-list > li').click(function () {
            if ($(window).width() <= 1199) {
                $(this).children('ul').fadeToggle(150);
            }
        });
        // If width is less or equal to 943px dropdowns are displayed on click (thanks Aman Jain from stackoverflow)

        $('.menu-toggle').click(function (e) {
            $('.menu').toggleClass('is-active');
            e.preventDefault();
        });
        // when clicked on mobile-menu, normal menu is shown as a list, classic rwd menu story (thanks mwl from stackoverflow)
    }());

}(jQuery));